import axios from "axios";
const host = process.env.REACT_APP_BACKEND_URL;

export const billerLogin = async (datas) => {
  try {
    const { data } = await axios.post(`${host}/billerLogin`, datas);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const billerLogout = async (datas) => {
  try {
    const { data } = await axios.put(`${host}/billerLogout`, datas);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

// export const getAllProducts = async (token, id) => {
//   try {
//     const { data } = await axios.get(
//       `${host}/billingAllProducts/${id}?offset=&group=&search=`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return data;
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

////////////////////////////////////////changeddiasodasf jksj/////////////////////////////////////

//////////////////////////////////////// getAllProducts /////////////////////////////////////
export const getAllProducts = async (token, id) => {
  try {
    const { data } = await axios.get(`${host}/getAllProducts/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

//////////////////////////////////////// getAllProducts /////////////////////////////////////

export const getAllGroupWithProducts = async (token, id) => {
  try {
    const { data } = await axios.get(
      `${host}/billerGetAllGroupWithProducts/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllGroup = async (token, id) => {
  try {
    const { data } = await axios.get(`${host}/getallgroup/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllOrders = async (token, activeBtn) => {
  try {
    const { data } = await axios.get(`${host}/getallorders/${activeBtn}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllOrdersForSearch = async (token, search) => {
  try {
    const { data } = await axios.get(
      `${host}/getAllOrdersForSearch/${search}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

// export const getAllKotProducts = async (token) => {
//   try {
//     const { data } = await axios.get(`${host}/billingGetAllKotProducts`, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//     return data;
//   } catch (error) {
//     return error.response.data.message;
//   }
// };

export const placeNewOrderFromBilling = async (datas, token) => {
  try {
    const { data } = await axios.post(
      `${host}/placeneworderfrombilling`,
      datas,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const cancelRecentOrderFromBilling = async (id, token) => {
  try {
    const { data } = await axios.put(
      `${host}/cancelrecentorderfrombilling/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const editRecentOrderFromBilling = async (id, token, updatedData) => {
  try {
    const { data } = await axios.put(
      `${host}/editrecentorderfrombilling/${id}`,
      updatedData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const sendOrderToKot = async (datas, token) => {
  console.log(datas);
  try {
    const { data } = await axios.post(`${host}/sendtokot`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const paymentGatewayDetails = async (amount, token) => {
  try {
    const { data } = await axios.post(
      `${host}/paymentgatewaydetails`,
      { amount: Number(amount) * 100 },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getRazorpayKey = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getrazorpaykey`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

////////////////////////////////////
export const getAllTables = async (token, vendor_id) => {
  try {
    const { data } = await axios.get(
      `${host}/billerGetAllTables/${vendor_id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const getAllTableAreas = async (token) => {
  try {
    const { data } = await axios.get(`${host}/billerGetAllTableAreas`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllCaptains = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getallcaptains`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const reserveTable = async (token, datas) => {
  try {
    const { data } = await axios.post(`${host}/reservetable`, datas, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getReservedTables = async (token) => {
  try {
    const { data } = await axios.get(`${host}/getreservedtables`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const deleteReservationDetails = async (token, id) => {
  try {
    const { data } = await axios.delete(
      `${host}/deletereservationdetails/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

// export const editReservationDetails = async (token, id) => {
//   try {
//     const { data } = await axios.put(
//       `${host}/editreservationdetails/${id}`,
//       {
//         headers: {
//           Authorization: `Bearer ${token}`,
//         },
//       }
//     );
//     return data;
//   } catch (error) {
//     return error.response.data.message;
//   }
// };
