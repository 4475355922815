import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Icon from "../../assets/ShopyWellpos.png";
import MenuIcon from "@mui/icons-material/Menu";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button, CssBaseline } from "@mui/material";
import Cookies from "js-cookie";
import { getAllOrdersForSearch } from "../../service/admin";
import { useSelector } from "react-redux";
import RecentOrderModal from "./RecentOrderModal";
import HoldOrderModal from "./HoldOrderModal";
import SidebarModal from "./SidebarModal";
import dayjs from "dayjs";
import ReservedTablesModal from "../table/ReservedTablesModal";
import ReserveTableModal from "../table/ReserveTableModalPopup";

export default function PosNavbar() {
  const {
    biller,
    clickedOrderTypeActiveBtn,
    switchedLanguage,
    recentOrderClickedItem,
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentpath = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const [orders, setOrders] = useState([]);

  const [search, setSearch] = useState("@");

  const [openHoldModal, setOpenHoldModal] = useState(false);
  const handleOpenHoldModal = () => setOpenHoldModal(true);
  const handleCloseHoldModal = () => setOpenHoldModal(false);

  const [openRecentOrderModal, setOpenRecentOrderModal] = useState(false);

  const [openSidebarModal, setOpenSidebarModal] = useState(false);
  const handleOpenSidebarModal = () => setOpenSidebarModal(true);
  const handleCloseSidebarModal = () => setOpenSidebarModal(false);

  const [isDayClose, setIsDayClose] = useState(true);
  const [isArabicLanguage, setIsArabicLanguage] = useState(switchedLanguage);

  const [clickedNavbarBtn, setClickedNavbarBtn] = useState("New Order");

  //////////////////////////////////////////Table Reservation////////////////////////////////////////////
  const [reserveTableBtn, setReserveTableBtn] = useState(false);
  const [reservedTableBtn, setReservedTableBtn] = useState(false);

  const [guests, setGuests] = useState(1);
  const [tableReservation, setTableReservation] = useState({
    reservedDate: null,
    reservedTime: null,
    bufferTime: null,
    name: null,
    mobile: null,
  });

  const handleOpenReserveTableModal = () => {
    setReserveTableBtn(true);
  };

  useEffect(() => {
    if (recentOrderClickedItem.length) {
      setClickedNavbarBtn("Recent Order");
    }
  }, [recentOrderClickedItem]);

  const handleCloseReserveTableModal = () => {
    setTableReservation({
      reservedDate: null,
      reservedTime: null,
      bufferTime: null,
      name: null,
      mobile: null,
    });
    setGuests(1);
    setReserveTableBtn(false);
  };

  const handleOpenReservedTableModal = () => setReservedTableBtn(true);
  const handleCloseReservedTableModal = () => {
    setReservedTableBtn(false);
  };

  const handleSetReservedDate = (date) => {
    const formattedDate = date ? dayjs(date).format("D MMM YY") : null;
    setTableReservation({ ...tableReservation, reservedDate: formattedDate });
  };

  const handleSetReservedTime = (time) => {
    const bufferBefore = 60; // Buffer time before reserved time in minutes
    const bufferAfter = 59; // Buffer time after reserved time in minutes

    const formattedTime = time ? dayjs(time).format("h:mm A") : null;

    const bufferStartTime = time
      ? dayjs(time).subtract(bufferBefore, "minutes").format("h:mm A")
      : null;

    // Calculate buffer end time by adding bufferAfter minutes to the formatted time
    const bufferEndTime = time
      ? dayjs(time).add(bufferAfter, "minutes").format("h:mm A")
      : null;

    setTableReservation({
      ...tableReservation,
      reservedTime: formattedTime,
      bufferTime: `${bufferStartTime} - ${bufferEndTime}`,
    });
  };

  ////////////////////////////////////////// Table Reservation ////////////////////////////////////////////

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    Cookies.set("biller", "");
    dispatch({
      type: "LOGOUT",
    });
    handleClose();
    navigate("/login");
  };

  const handleGetOrders = async () => {
    const data = await getAllOrdersForSearch(biller.token, search);

    setOrders(data);
  };

  useEffect(() => {
    handleGetOrders();
  }, [search]);

  const handleNewOrder = () => {
    setClickedNavbarBtn("New Order");
    if (currentpath.pathname === "/") {
      window.location.reload();
      dispatch({
        type: "resetOrderTypeActiveBtn",
        payload: clickedOrderTypeActiveBtn,
      });

      navigate("/");
      dispatch({ type: "reset", payload: [] });
    } else {
      dispatch({
        type: "resetOrderTypeActiveBtn",
        payload: clickedOrderTypeActiveBtn,
      });

      navigate("/");
      dispatch({ type: "reset", payload: [] });
    }
  };

  const handleOrderListBtn = () => {
    dispatch({
      type: "resetOrderTypeActiveBtn",
      payload: clickedOrderTypeActiveBtn,
    });

    navigate("/orders");
    dispatch({ type: "reset", payload: [] });
  };

  const handleRunningOrderBtn = () => {
    if (currentpath.pathname === "/") {
      window.location.reload();
      dispatch({
        type: "resetOrderTypeActiveBtn",
        payload: clickedOrderTypeActiveBtn,
      });

      navigate("/");
      dispatch({ type: "reset", payload: [] });
    } else {
      dispatch({
        type: "resetOrderTypeActiveBtn",
        payload: clickedOrderTypeActiveBtn,
      });

      navigate("/");
      dispatch({ type: "reset", payload: [] });
    }
  };

  const handleIsdayClose = () => {
    setIsDayClose(!isDayClose);
  };

  const checkExpiration = setInterval(() => {
    const expirationTime = localStorage.getItem("billingExpiration");
    if (expirationTime && Date.now() > expirationTime) {
      // If the expiration time has passed, log the user out
      clearInterval(checkExpiration);
      localStorage.removeItem("billingExpiration");
      handleLogout();
    }
  }, 60 * 60 * 1000); // Every hour, check

  const handleLanguage = () => {
    setIsArabicLanguage(!isArabicLanguage);
    setAnchorEl(null);

    Cookies.set("selectedLanguage", !isArabicLanguage);
    dispatch({
      type: "isArabicLanguage",
      payload: !isArabicLanguage,
    });
  };
  // console.log(isArabicLanguage)

  return (
    <>
      {/* Hold Order Modaal */}
      {openHoldModal && (
        <HoldOrderModal
          handleOpenHoldModal={handleOpenHoldModal}
          handleCloseHoldModal={handleCloseHoldModal}
        />
      )}

      {/* Recent Order Modaal */}
      {openRecentOrderModal && (
        <RecentOrderModal
          openRecentOrderModal={openRecentOrderModal}
          setOpenRecentOrderModal={setOpenRecentOrderModal}
        />
      )}

      {/* Side bar Modaal */}
      {openSidebarModal && (
        <SidebarModal
          handleOpenSidebarModal={handleOpenSidebarModal}
          handleCloseSidebarModal={handleCloseSidebarModal}
        />
      )}

      {reserveTableBtn && (
        <ReserveTableModal
          handleOpenReserveTableModal={handleOpenReserveTableModal}
          handleCloseReserveTableModal={handleCloseReserveTableModal}
          guests={guests}
          setGuests={setGuests}
          handleSetReservedDate={handleSetReservedDate}
          handleSetReservedTime={handleSetReservedTime}
          tableReservation={tableReservation}
          reservedTableBtn={reservedTableBtn}
        />
      )}

      {reservedTableBtn && (
        <ReservedTablesModal
          handleOpenReservedTableModal={handleOpenReservedTableModal}
          handleCloseReservedTableModal={handleCloseReservedTableModal}
        />
      )}

      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            zIndex: (theme) => theme.zIndex.drawer + 1,
            background: "var(--bg-white)",
            borderBottom: "solid var(--border-gray) 1px",
          }}
          elevation={0}
        >
          <Toolbar
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              height: "6vh",
              gap: "10px",
            }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                marginTop: 1,
                color: "var(--font-black)",
                display: "flex",
                gap: "8px",
                justifyContent: "center",
              }}
            >
              <MenuIcon
                onClick={handleOpenSidebarModal}
                sx={{ cursor: "pointer" }}
              />
              <img style={{ width: "130px" }} src={Icon} alt="shopywell-icon" />
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "85%",
                gap: "2px",
              }}
            >
              <Button
                onClick={handleNewOrder}
                variant="outlined"
                sx={{
                  textTransform: "none",
                  color:
                    clickedNavbarBtn === "New Order"
                      ? "var(--font-white)"
                      : "var(--font-black)",
                  backgroundColor:
                    clickedNavbarBtn === "New Order"
                      ? "var(--red)"
                      : "var(--font-white)",
                  borderColor:
                    clickedNavbarBtn === "New Order"
                      ? "var(--red)"
                      : "var(--border-grey)",
                  "&:hover": {
                    backgroundColor:
                      clickedNavbarBtn === "New Order"
                        ? "var(--red)"
                        : "var(--font-white)",
                    borderColor:
                      clickedNavbarBtn === "New Order"
                        ? "var(--red)"
                        : "var(--border-grey)",
                  },
                  fontFamily: "poppins",
                  cursor: "pointer",
                  borderRadius: "10px",
                  height: "40px",
                  whiteSpace: "nowrap",
                  // fontSize:"1em"
                }}
              >
                {isArabicLanguage ? "طلبات جديدة" : "New Order"}
              </Button>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={() => {
                    setOpenRecentOrderModal(true);
                  }}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color:
                      clickedNavbarBtn === "Recent Order"
                        ? "var(--font-white)"
                        : "var(--font-black)",
                    borderColor:
                      clickedNavbarBtn === "Recent Order"
                        ? "var(--red)"
                        : "var(--border-grey)",
                    backgroundColor:
                      clickedNavbarBtn === "Recent Order"
                        ? "var(--red)"
                        : "var(--font-white)",
                    "&:hover": {
                      backgroundColor:
                        clickedNavbarBtn === "Recent Order"
                          ? "var(--red)"
                          : "var(--font-white)",
                      borderColor:
                        clickedNavbarBtn === "Recent Order"
                          ? "var(--red)"
                          : "var(--border-grey)",
                    },
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "10px",
                    height: "40px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {isArabicLanguage ? "النظام الأخير" : "Recent Order"}
                </Button>
                {/* <Button
                  onClick={() => setOpenHoldModal(true)}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color: "var(--font-black)",
                    borderColor: "var(--border-grey)",
                    backgroundColor: "var(--font-white)",
                    "&:hover": {
                      backgroundColor: "var(--font-white)",
                      borderColor: "var(--border-grey)",
                    },
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "10px",
                    height: "40px",
                    whiteSpace: "nowrap",
                  }}
                >
                  On Hold
                </Button> */}
                <Button
                  onClick={handleOrderListBtn}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color: "var(--font-black)",
                    borderColor: "var(--border-grey)",
                    backgroundColor: "var(--font-white)",
                    "&:hover": {
                      backgroundColor: "var(--font-white)",
                      borderColor: "var(--border-grey)",
                    },
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "10px",
                    height: "40px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {isArabicLanguage ? "قائمة الطلبات" : "Order List"}
                </Button>
                {/* <Button
                  onClick={handleRunningOrderBtn}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color: "var(--font-black)",
                    borderColor: "var(--border-grey)",
                    backgroundColor: "var(--font-white)",
                    "&:hover": {
                      backgroundColor: "var(--font-white)",
                      borderColor: "var(--border-grey)",
                    },
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "10px",
                    height: "40px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {isArabicLanguage ? "أوامر الجري" :"Running Orders"}
                </Button> */}
                {/* ////////////////////////////////////////////////////////////////////// */}
                <Button
                  onClick={handleOpenReserveTableModal}
                  variant="outlined"
                  sx={{
                    textTransform: "none",
                    color: "var(--font-black)",
                    borderColor: "var(--border-grey)",
                    backgroundColor: "var(--font-white)",
                    "&:hover": {
                      backgroundColor: "var(--font-white)",
                      borderColor: "var(--border-grey)",
                    },
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "10px",
                    height: "40px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {switchedLanguage ? "الاحتياطي" : "Reserve table"}
                </Button>

                <Button
                  variant="outlined"
                  onClick={handleOpenReservedTableModal}
                  sx={{
                    textTransform: "none",
                    color: "var(--font-black)",
                    borderColor: "var(--border-grey)",
                    backgroundColor: "var(--font-white)",
                    "&:hover": {
                      backgroundColor: "var(--font-white)",
                      borderColor: "var(--border-grey)",
                    },
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "10px",
                    height: "40px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {switchedLanguage ? "الجداول المحجوزة" : "Reserved Tables"}
                </Button>
              </div>
              <Box sx={{ display: "flex", gap: "10px" }}>
                {/* <Button
                  onClick={handleIsdayClose}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    color: "var(--font-white)",
                    backgroundColor: "var(--red)",
                    "&:hover": {
                      backgroundColor: "var(--red)",
                    },
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "10px",
                    height: "40px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {isDayClose ? "Day Close" : "Day Open"}
                </Button> */}

                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={orders ? orders : []}
                  getOptionLabel={(option) => {
                    if (search && option.orderId.includes(search)) {
                      return option.orderId;
                    } else if (search && option.invoiceNo.includes(search)) {
                      return option.invoiceNo;
                    } else {
                      return ""; // Return empty string if no match found
                    }
                  }}
                  sx={{
                    width: "200px",
                    height: "40px",
                    borderRadius: "15px",
                    border: "1px solid #8B8B8B",
                    outline: "none",
                    padding: "0",

                    ".MuiOutlinedInput-input": {
                      fontFamily: "poppins",
                      height: "7px",

                      // borderRadius: "5px",
                    },
                    ".MuiInputBase-input": {
                      fontSize: "14px",
                      // marginTop:"-4px"
                    },
                    ".MuiInputBase-root": {
                      fontFamily: "poppins",
                      borderRadius: "13px",
                    },

                    ".MuiFormLabel-root": {
                      fontFamily: "poppins",
                      fontSize: "15px",
                      marginTop: "-2px",
                    },
                    ".MuiButtonBase-root": {
                      marginTop: "-1px",
                    },
                    "&.Mui-focused ": {
                      borderWidth: "none",
                      borderColor: "none",
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={
                        isArabicLanguage
                          ? "ترتيب أو رقم الفاتورة"
                          : "Order or Invoice No."
                      }
                      // variant="standard"
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                  )}
                  onChange={(e, data) => {
                    if (data) {
                      dispatch({
                        type: "recentOrderSingleItem",
                        payload: [data],
                      });
                      navigate("/");
                    }
                  }}
                />
              </Box>
            </div>

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="white"
              >
                <AccountCircle />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>
                  {biller?.vendor?.username}
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  {isArabicLanguage ? "حسابي" : "My account"}
                </MenuItem>
                <MenuItem onClick={handleLanguage}>
                  {isArabicLanguage ? "English" : "عربي"}
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  {isArabicLanguage ? "تسجيل الخروج" : "Logout"}
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
