import Cookies from "js-cookie";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import { billerLogin } from "../service/admin";
import { useFormik } from "formik";
import { loginSchema } from "../schemas/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import PosImage from "../assets/PosImage.png";
import Logo from "../assets/Logo.png";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector } from "react-redux";

const { makeStyles } = require("@mui/styles");

const useStyles = makeStyles((theme) => ({
  main: {
    height: "100vh",
    width: "100%",
    backgroundColor: "#FFFFFF",
    display: "grid",
    gridTemplateColumns: "1.5fr 1fr",
    gridTemplateAreas: `
    'leftPart rightPart'
  `,
  },
  leftPart: {
    gridArea: "leftPart",
    background: "#FFFFFF",
  },
  rightPart: {
    gridArea: "rightPart",
    background: "#FFFFFF",
  },
  leftImage: {
    width: "100%",
    height: "100%",
  },
  rightElem: {
    background: "#FFFFFF",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  error_msg: {
    color: "red",
    fontSize: "1rem",
    margin: 0,
    fontFamily: "poppins",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    pb: "2rem",
  },
}));
const customTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
  },
});
function LoginPage() {
  const { switchedLanguage } = useSelector((state) => state);
  const classes = useStyles();
  const [isLogin, setIsLogin] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      phone: "",
      password: "",
      deviceId: "8113928182",
    },
    validationSchema: loginSchema,
    onSubmit: async (values, action) => {
      setIsLogin(true);
      const data = await billerLogin(values);
      console.log(data)
      if (data.status) {
        dispatch({ type: "LOGIN", payload: data });
        Cookies.set("biller", JSON.stringify(data));
        localStorage.setItem(
          "billingExpiration",
          Date.now() + 1 * 24 * 60 * 60 * 1000
        ); // 1 days from now
        navigate("/");
        action.resetForm();
      } else {
        setIsLogin(false);
        toast.error(data.message);
      }
    },
  });
  return (
    <>
      <ToastContainer />
      <Box dir={switchedLanguage ? "ltr" : "ltr"} className={classes.main}>
        <Box className={classes.leftPart}>
          <Box className={classes.leftImage}>
            <Box
              sx={{
                position: "absolute",
                left: "5%",
                top: "10%",
              }}
            >
              <img
                src={Logo}
                alt=""
                style={{
                  width: "200px",
                  paddingBottom: 90,
                }}
              />
              <Typography
                sx={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  pb: 5,
                }}
              >
                KIOSK FOR <br />
                <span style={{ color: "#F40000" }}> EASY ORDER</span>
              </Typography>
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  color: "#7B7B7B",
                }}
              >
                Make your restaurant <br /> stand out
              </Typography>
            </Box>
            <img
              src={PosImage}
              alt=""
              style={{ height: "99.5vh", width: "100%" }}
            />
            <Typography
              sx={{
                width: "300px",
                position: "absolute",
                left: "5%",
                top: "93%",
              }}
            >
              www.shopywell.com
            </Typography>
          </Box>
        </Box>
        <Box className={classes.rightPart}>
          <Box className={classes.rightElem}>
            <Box>
              <Box sx={{ pb: "2.1rem" }}>
                <Typography sx={{ fontSize: "1.5rem", fontWeight: "bold" }}>
                  Welcome back!
                </Typography>
              </Box>
              <Box component="form" onSubmit={formik.handleSubmit} noValidate>
                <Box sx={{ pb: "1rem", width: "30vw" }}>
                  <Box
                    sx={{
                      border: "1px solid #EEEEEE",
                      width: "100%",
                      height: "60px",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      px: "1.5rem",
                      bgcolor: "#F5F5F5",
                    }}
                  >
                    <PersonIcon sx={{ fontSize: "1.7rem", color: "#92929B" }} />
                    <TextField
                      variant="outlined"
                      placeholder="phone number"
                      sx={{
                        "& fieldset": { border: "none" },
                        width: "100%",
                      }}
                      name="phone"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                    />
                  </Box>
                </Box>
                <Box sx={{ pb: "1rem" }}>
                  <Box
                    sx={{
                      border: "1px solid #EEEEEE",
                      width: "100%",
                      height: "60px",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      px: "1.5rem",
                      bgcolor: "#F5F5F5",
                    }}
                  >
                    <LockIcon sx={{ fontSize: "1.7rem", color: "#92929B" }} />
                    <TextField
                      variant="outlined"
                      type="password"
                      placeholder="at least 6 character"
                      sx={{
                        "& fieldset": { border: "none" },
                        width: "100%",
                      }}
                      name="password"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                  </Box>
                </Box>
                {formik.touched.phone && formik.errors.phone && (
                  <p className={classes.error_msg}>{formik.errors.phone}</p>
                )}
                {formik.touched.password && formik.errors.password && (
                  <p className={classes.error_msg}>{formik.errors.password}</p>
                )}
                <Box sx={{ pb: "1.5rem", pt: "1.1rem" }}>
                  <Button
                    type="submit"
                    disabled={isLogin}
                    sx={{
                      background: "#F40000",
                      width: "100%",
                      height: "60px",
                      borderRadius: "10px",
                      display: "flex",
                      alignItems: "center",
                      boxSizing: "border-box",
                      px: "1.5rem",
                      color: "white",
                      "&:hover": {
                        color: "white",
                        background: "#F40000",
                      },
                    }}
                  >
                    {isLogin ? (
                      <ThemeProvider theme={customTheme}>
                        <CircularProgress color="primary" />
                      </ThemeProvider>
                    ) : (
                      <Typography sx={{ color: "white", fontSize: ".9rem" }}>
                        Login
                      </Typography>
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default LoginPage;
