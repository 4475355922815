export const OrderTypeActiveBtnReducer = (initialState = "Dine in", action) => {
  switch (action.type) {
    case "clickedOrderTypeActiveBtn":
      return action.payload;

    case "resetOrderTypeActiveBtn":
      return initialState;

    default:
      return initialState;
  }
};
