export const GuestReducer = (initialState = 1, action) => {
    switch (action.type) {
      case "noOfGuests":
        return action.payload;
  
      case "reset":
        return initialState;
  
      default:
        return initialState;
    }
  };
  