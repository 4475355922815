
export const productReducer = (
  state = localStorage.getItem("SET_PRODUCTS")
    ? JSON.parse(localStorage.getItem("SET_PRODUCTS"))
    : [],
  action
) => {
  switch (action.type) {
    case "SET_PRODUCTS":
      return action.payload;

    default:
      return state;
  }
};
