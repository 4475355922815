export const TableReducer = (initialState = [], action) => {
  switch (action.type) {
    case "table":
      return action.payload;

    case "reset":
      return initialState;

    default:
      return initialState;
  }
};
