import React, { useEffect, useState } from "react";
import { Box, Modal, Paper, Typography, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { getAllTables, getReservedTables } from "../../service/admin";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const BillingTablePopup = ({
  handleOpenTableModal,
  handleCloseTableModal,
  handleKotBtn,
  isKOTBtnClicked,
}) => {
  const dispatch = useDispatch();
  const { selectedTable, noOfGuests, biller, switchedLanguage } = useSelector(
    (state) => state
  );

  const [addTables, setAddTables] = useState(selectedTable);
  const [addGuests, setAddGuests] = useState(noOfGuests);
  const [fetchedTables, setFetchedTables] = useState([]);

  //////////////////////////////////////Filter reserved tables///////////////////////////////////////

  const [todayDateAndTime, setTodayDateAndTime] = useState({
    todayDate: dayjs(new Date(Date.now())).format("D MMM YY"),
    todayTime: dayjs(new Date(Date.now())).format("h:mm A"),
  });
  const [reservedTables, setReservedTables] = useState([]);
  const [reservedData, setReservedData] = useState([]);

  const allReservedTables = async () => {
    let res = await getReservedTables(biller.token);
    setReservedData(res);
  };

  useEffect(() => {
    let previousMinute = null;

    const updateDateAndTime = () => {
      const currentDate = new Date(Date.now());
      const formattedDate = dayjs(currentDate).format("D MMM YY");
      const formattedTime = dayjs(currentDate).format("h:mm A");
      const currentMinute = currentDate.getMinutes();

      if (currentMinute !== previousMinute) {
        setTodayDateAndTime({
          ...todayDateAndTime,
          todayDate: formattedDate,
          todayTime: formattedTime,
        });

        previousMinute = currentMinute;
      }
    };

    // Call the updateDateAndTime function immediately
    updateDateAndTime();

    // Set up the interval to call updateDateAndTime every second
    const interval = setInterval(() => {
      updateDateAndTime();
    }, 1000);

    // Clean up the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, []);

  const checkReservedTables = () => {
    if (todayDateAndTime.todayDate || todayDateAndTime.todayTime) {
      const notReservedTables = reservedData.filter((tbls) => {
        if (tbls.reservedDate == todayDateAndTime.todayDate) {
          if (
            tbls.bufferTime.split(" - ")[0] < todayDateAndTime.todayTime &&
            tbls.bufferTime.split(" - ")[1] > todayDateAndTime.todayTime
          ) {
            return tbls;
          }
        }
      });
      let selectTable = [];
      notReservedTables.map((tbl) => {
        tbl.tables.map((item) => {
          selectTable.push(item.table._id);
        });
      });
      setReservedTables(selectTable);
    }
  };

  //////////////////////////////////////Filter reserved tables///////////////////////////////////////

  useEffect(() => {
    fetchAllTables();
    allReservedTables();
    checkReservedTables();
  }, [addTables, todayDateAndTime.todayTime, reservedData]);

  const fetchAllTables = async () => {
    let allTables = await getAllTables(biller.token, biller.vendor._id);

    let filteredTables = allTables.filter(
      (tbl) => !addTables.some((selectedTbl) => selectedTbl._id === tbl._id)
    );

    setFetchedTables(filteredTables);
  };

  const handleDeleteTable = (tbl) => {
    let filterTable = addTables.filter((t) => t._id !== tbl._id);
    setAddTables(filterTable);
  };

  const handleAddTable = (tbl) => {
    setAddTables([...addTables, tbl]);
    fetchAllTables();
  };

  const handlecancelBtn = () => {
    setAddTables(selectedTable);
    handleCloseTableModal();
  };

  const handleConfirmBtn = () => {
    if (addTables.length > 0) {
      dispatch({ type: "table", payload: addTables });
      dispatch({ type: "noOfGuests", payload: addGuests });

      if (isKOTBtnClicked) {
        handleKotBtn();
      }
      handleCloseTableModal();
      
    } else {
      toast.error(
        switchedLanguage ? "الرجاء اختيار طاولة!" : "Please select a table!"
      );
    }

    console.log(selectedTable.length, "slectedTbl");
  };

  // Function to convert a number to Arabic numerals
  function toArabicNumerals(number) {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return String(number).replace(/\d/g, (digit) => arabicNumerals[digit]);
  }

  return (
    <>
      {
        <Modal
          open={handleOpenTableModal}
          onClose={handlecancelBtn}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper
            sx={{ border: "none", display: "flex", justifyContent: "center" }}
          >
            <Box
              sx={{
                position: "absolute",
                left: "50%",
                padding: "26px",
                backgroundColor: "background.paper",
                width: "25em",
                borderRadius: "5px",
                top: "50%",
                transform: "translate(-50%,-50%)",
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                borderRadius: "17px",
              }}
            >
              <Box sx={{ marginBottom: "30px" }}>
                <sup
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <CloseIcon
                    onClick={handlecancelBtn}
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "18px",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  />
                </sup>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "17px",
                    fontWeight: "600",
                    textAlign: "center",
                  }}
                >
                  {switchedLanguage ? "اختر الجدول" : "Choose Table"}
                </Typography>
              </Box>

              <Box>
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      marginBottom: "6px",
                    }}
                  >
                    {switchedLanguage ? "إضافة الجداول" : "Add Tables"}
                  </Typography>
                  <FormControl fullWidth sx={{ height: "40px" }}>
                    <Select
                      sx={{
                        height: "40px",
                        border: "1px solid #F0F0F0",
                        borderRadius: "18px",
                      }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={(e) => handleAddTable(e.target.value)}
                    >
                      {fetchedTables?.map((tbl, index) => (
                        <MenuItem
                          key={index}
                          sx={{ fontFamily: "poppins" }}
                          value={tbl}
                          // disabled={reservedTables.includes(tbl._id)}
                        >
                          {`T-${
                            switchedLanguage
                              ? toArabicNumerals(tbl.tableNo)
                              : tbl.tableNo
                          }`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box sx={{ marginBottom: "20px" }}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      marginBottom: "6px",
                    }}
                  >
                    {switchedLanguage ? "الجداول" : "Tables"}
                  </Typography>
                  <TextField
                    variant="standard"
                    InputProps={{
                      disableUnderline: true,

                      startAdornment: (
                        <div style={{ display: "flex", gap: "10px" }}>
                          {addTables.map((table) => (
                            <button
                              key={table}
                              variant="contained"
                              style={{
                                display: "flex",
                                borderRadius: "4px",
                                alignItems: "center",
                                gap: "8px",
                                backgroundColor: "#FFFFFF",
                                border: "none",
                              }}
                            >
                              <Typography
                                sx={{
                                  fontFamily: "poppins",
                                  fontSize: "14px",
                                  whiteSpace: "nowrap",
                                }}
                              >{`T-${
                                switchedLanguage
                                  ? toArabicNumerals(table.tableNo)
                                  : table.tableNo
                              }`}</Typography>

                              <CloseIcon
                                onClick={() => handleDeleteTable(table)}
                                sx={{
                                  fontFamily: "poppins",
                                  fontSize: "13px",
                                  cursor: "pointer",
                                  fontWeight: "bold",
                                }}
                              />
                            </button>
                          ))}
                        </div>
                      ),
                    }}
                    sx={{
                      width: "100%",
                      fontFamily: "poppins",
                      borderRadius: "18px",
                      backgroundColor: "#F2F3F7",
                      border: "1px solid #F0F0F0",
                      outline: "none",
                      height: "45px",
                      display: "flex",
                      justifyContent: "center",
                      padding: "20px",
                      overflow: "scroll",
                      "&::-webkit-scrollbar": {
                        display: "none", // Hide scrollbar
                      },
                    }}
                  ></TextField>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      marginBottom: "6px",
                    }}
                  >
                    {switchedLanguage ? "ضيف" : "Guest"}
                  </Typography>

                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <button
                      onClick={() => setAddGuests(addGuests - 1)}
                      disabled={addGuests === 1 ? true : false}
                      style={{
                        border: "1px solid #F0F0F0",
                        height: "38px",
                        width: "48px",
                        borderRadius: "18px",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <RemoveIcon />
                    </button>
                    <Typography sx={{ fontFamily: "poppins" }}>
                      {switchedLanguage
                        ? addGuests.toLocaleString("ar-EG")
                        : addGuests}
                    </Typography>
                    <button
                      onClick={() => setAddGuests(addGuests + 1)}
                      style={{
                        border: "1px solid #F0F0F0",
                        fontFamily: "poppins",
                        height: "38px",
                        width: "48px",
                        borderRadius: "18px",
                        backgroundColor: "#FFFFFF",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",

                        cursor: "pointer",
                      }}
                    >
                      <AddIcon />
                    </button>
                  </Box>
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <Button
                  onClick={handlecancelBtn}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "43px",
                    backgroundColor: "#FFFFFF",
                    color: "#4D575D",
                    border: "1px solid #F0F0F0",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#FFFFFF",
                    },
                  }}
                >
                  {switchedLanguage ? "إلغاء" : "Cancel"}
                </Button>
                <Button
                  onClick={handleConfirmBtn}
                  variant="contained"
                  sx={{
                    boxShadow: "none",
                    width: "48%",
                    height: "43px",
                    backgroundColor: "#f40000",
                    color: "#f2f3f8",
                    border: "none",
                    fontFamily: "poppins",
                    cursor: "pointer",
                    borderRadius: "11px",
                    textTransform: "none",
                    "&:hover": {
                      boxShadow: "none",
                      backgroundColor: "#f40000",
                    },
                  }}
                >
                  {switchedLanguage ? "التأكيد" : "Confirm"}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Modal>
      }
    </>
  );
};

export default BillingTablePopup;
