import { Box, Card, Button, Typography, Divider } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ExpandMore } from "@mui/icons-material";

import styled from "@emotion/styled";
import Moment from "react-moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import "moment/locale/ar";

const BuyIcon = styled(Box)({
  backgroundColor: "var(--red)",
  color: "var(--font-white)",
  width: "100px",
  height: "32px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "5px",
});

function DeliveredOrderCard({ orders, type, query, keys }) {
  const { switchedLanguage } = useSelector((state) => state);
  return (
    <Card
      elevation={0}
      sx={{
        margin: 0.5,
        p: 1,
        pb: 5,
        background: "var(--bg-forth)",
        height: { lg: "76vh", md: "72vh" },
      }}
    >
      <span style={{}}>
        <b>{switchedLanguage ? "تسليم" : "Delivered"}</b>
      </span>
      <Box
        sx={{
          mt: 1,
          height: "100%",
          overflow: "hidden",
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            display: "none", // Hide scrollbar
          },
        }}
      >
        {orders
          ?.filter((item) =>
            keys.some((key) => item[key]?.toLowerCase()?.includes(query))
          )
          ?.map((item, index) => (
            <Box key={item._id}>
              {type === "All" ? (
                <CardBody item={item} />
              ) : (
                item.typeofOrder == type && <CardBody item={item} />
              )}
            </Box>
          ))}
      </Box>
    </Card>
  );
}

export default DeliveredOrderCard;

function CardBody({ item }) {
  const { switchedLanguage } = useSelector((state) => state);

  // Function to convert a number to Arabic numerals
  function toArabicNumerals(number) {
    const arabicNumerals = ["٠", "١", "٢", "٣", "٤", "٥", "٦", "٧", "٨", "٩"];
    return String(number).replace(/\d/g, (digit) => arabicNumerals[digit]);
  }
  return (
    <Card
      elevation={0}
      sx={{
        mb: 2,
        p: 1.5,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <BuyIcon sx={{ width: { sm: "70px", md: "70px", lg: "100px" } }}>
            <Typography
              sx={{ fontSize: { sm: "13px", md: "13px", lg: "15px" } }}
            >
              {item?.typeofOrder == "Dine in" && switchedLanguage
                ? "على الطاولة"
                : item?.typeofOrder == "Delivery" && switchedLanguage
                ? "التسليم"
                : item?.typeofOrder == "Pickup" && switchedLanguage
                ? "أمر التقاط"
                : item?.typeofOrder}
            </Typography>
          </BuyIcon>
          <Box
            sx={{
              display: "flex",
              alignContent: "end",
              textAlign: "end",
            }}
          >
            <Typography
              sx={{ fontSize: { sm: "13px", md: "12px", lg: "15px" } }}
            >
              <Moment format="MM/D/YY,h:mm" withTitle locale={switchedLanguage ? "ar" : "en"}>
                {item?.createdAt}
              </Moment>
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "8px",
          }}
        >
          <Typography
            sx={{
              fontSize: { sm: "13px", md: "13px", lg: "14px", display: "flex" },
            }}
          >
            <span style={{ fontWeight: "bolder" }}>
              {switchedLanguage ? "رقم الفاتورة" : "Invoice No"} :{" "}
            </span>

            <span
              style={{
                fontWeight: switchedLanguage ? "bolder" : "",
                fontSize: switchedLanguage ? "18px" : "",
              }}
            >
              {switchedLanguage
                ? toArabicNumerals(item?.invoiceNo)
                : item?.invoiceNo}
            </span>
          </Typography>

          {item?.typeofOrder == "Delivery" || item?.typeofOrder == "Pickup" ? (
            ""
          ) : (
            <Box
              sx={{
                display: "flex",
                alignContent: "end",
                textAlign: "end",
              }}
            >
              <Typography
                sx={{
                  fontSize: { sm: "13px", md: "13px", lg: "14px" },
                }}
              >
                <span style={{ fontWeight: "bolder" }}>
                  {switchedLanguage ? "الجدول رقم" : "Table No"}
                </span>
                :{switchedLanguage
                  ? toArabicNumerals(item?.tableNo)
                  : item?.tableNo}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ mt: "15px" }}>
        <Products item={item} />
      </Box>
    </Card>
  );
}
function Products({ item }) {
  const { switchedLanguage, biller } = useSelector((state) => state);
  const [open, setOpen] = useState(false);

  // Currency
  const currency =
    switchedLanguage && biller?.vendor?.country == "UAE"
      ? "الدرهم"
      : switchedLanguage && biller?.vendor?.country == "KSA"
      ? "ريال"
      : !switchedLanguage && biller?.vendor?.country == "IND"
      ? "₹"
      : "";

  return (
    <>
      {open ? (
        <>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <ExpandLessIcon onClick={() => setOpen(false)} />
          </Box>
          {item?.products?.map((product) => (
            <>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={{ fontSize: "14px" }}>
                  {switchedLanguage
                    ? `${(product?.quantity).toLocaleString("ar-EG")} ${
                        product?.varientId?.productName
                      } x`
                    : `${product?.quantity} x ${product?.varientId?.productName}`}

                  <br />
                </Typography>
                <Typography sx={{ fontSize: "14px" }}>
                  {switchedLanguage ? "" : currency}{" "}
                  {switchedLanguage
                    ? (product?.quantity * product?.salePrice).toLocaleString(
                        "ar-EG"
                      )
                    : product?.quantity * product?.salePrice}{" "}
                  {switchedLanguage ? "ريال" : ""}
                  <br />
                </Typography>
              </Box>
              <Box sx={{ ml: "10px" }}>
                {product?.addOns.length > 0 &&
                  product?.addOns?.map((addOn) => (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      <Typography sx={{ fontSize: "14px" }} value={addOn?._id}>
                        {switchedLanguage
                          ? `${(addOn?.quantity).toLocaleString("ar-EG")} ${
                              addOn?.name
                            } x`
                          : `${addOn?.quantity} x ${addOn?.name}`}

                        <br />
                      </Typography>
                      <Typography sx={{ fontSize: "14px" }} value={addOn?._id}>
                        {switchedLanguage ? "" : currency}{" "}
                        {switchedLanguage
                          ? addOn?.price.toLocaleString("ar-EG")
                          : addOn?.price}
                        {switchedLanguage ? "ريال" : ""}
                        <br />
                      </Typography>
                    </Box>
                  ))}
              </Box>
            </>
          ))}
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Divider style={{ width: "100px", color: "black" }} />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "bold",
              }}
            >
              {switchedLanguage ? "" : currency}{" "}
              {switchedLanguage
                ? item.products
                    .reduce((accumulator, curValue) => {
                      return accumulator + curValue.subTotal;
                    }, 0)
                    .toLocaleString("ar-EG")
                : item.products.reduce((accumulator, curValue) => {
                    return accumulator + curValue.subTotal;
                  }, 0)}{" "}
              {switchedLanguage ? "ريال" : ""}
            </Typography>
          </Box>
          {item.typeofOrder === "Delivery" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "15px",
              }}
            >
              <Box sx={{ width: "10%" }}>
                {/* <Typography>OTP: 123456</Typography> */}
              </Box>

              <Box>
                <Typography sx={{ fontSize: "15px" }}>
                  {switchedLanguage ? "انها مخصصة ل" : "Assigned"} :
                  {item?.DeliveryBoyId?.first_name ? (
                    <>
                      {" "}
                      {item.deliveryBoy.first_name}{" "}
                      {item.deliveryBoy.first_name}
                    </>
                  ) : switchedLanguage ? (
                    "ولد التسليم لم تعين"
                  ) : (
                    "Not Assigned"
                  )}
                </Typography>
              </Box>
            </Box>
          )}

          {/* <Box
            sx={{ display: "flex", justifyContent: "space-evenly", mt: "15px" }}
          >
            <Button
              variant="contained"
              sx={{
                background: "var(--button-gray)",
                width: "130px",
                mr: "10px",
                color: "var(--font-black)",
                textTransform: "none",
                borderRadius: "5px",
                "&:hover": {
                  backgroundColor: "var(--button-gray)",
                },
              }}
            >
              Print
            </Button>
          </Box> */}
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <ExpandMore onClick={() => setOpen(true)} />
        </Box>
      )}
    </>
  );
}
